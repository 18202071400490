import React from "react";

export const Why = () => {
  return (
    <>
      As I mentioned earlier, computers have always been my passion. However,
      for many years, I never considered it as a potential profession.
      <br />
      <br /> I possess an innate curiosity, particularly in understanding the
      mechanics behind things, as well as the creation of products and services.
      This very curiosity steered me towards the study of Industrial Design, a
      field that provided me with a plethora of answers to my inquisitive
      inquiries.
      <br />
      <br /> Over time, my curiosity remained strong, but a transformation was
      occurring in my perspective. The rapid evolution of technology and its
      profound impact on society, within a world that's profoundly
      interconnected, intensified my interest in digital products and services.
      <br />
      <br /> Today, I'm thrilled to be a developer. Naturally, I'm continuously
      learning, and that's what keeps me motivated. The ever-present prospect of
      tasks to tackle and my eagerness to explore upcoming technologies fuels my
      enthusiasm. And, as always, it gives me my favorite fuel... curiosity.
    </>
  );
};
