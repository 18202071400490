import pubExt from "../../../assets/projects/pub-ext.webp";
import manuals from "../../../assets/projects/manuals.webp";
import artNorush from "../../../assets/projects/art-norush.webp";
import logotype from "../../../assets/projects/logo-design.webp";
import uxui from "../../../assets/projects/uxui.webp";

const graphicDesigns = [
  {
    type: "graphic",
    title: "Outdoor Advertising",
    description: "Street billboards and store advertising",
    imgUrl: pubExt,
    info: "While employed as an Art Director at Pubext 2000 and freelancing for RótulosAlex in Malaga, Spain, I had the privilege of contributing to a diverse range of projects. These experiences encompassed aiding local small-scale businesses as well as collaborating with well-known brands, including construction companies.",
    repo: "",
    video: "",
    test: "",
    pictures: [
      "pub-exterior-1.png",
      "pub-exterior-2.png",
      "pub-exterior-3.png",
      "pub-exterior-4.png",
    ],
    client: "Via Célere, Admiral and many other brands",
    company: "Pubext, Imaginall, freelancer",
    stack: "Photoshop, Illustrator",
  },
  {
    type: "graphic",
    title: "Corporate Manuals",
    description: "Identity manuals, instructions, books",
    imgUrl: manuals,
    info: "Throughout my career, I've collaborated with numerous brands, undertaking diverse responsibilities such as orchestrating their rebranding initiatives, crafting comprehensive instruction manuals, and curating captivating advertising magazines. Additionally, I've had the opportunity to create meticulous corporate identity manuals tailored to the needs of smaller enterprises.",
    repo: "",
    video: "",
    test: "",
    pictures: ["manual-1.png", "manual-2.png", "manual-3.png", "manual-4.png"],
    client: "Via Célere, Personal and many other brands",
    company: "Pubext, Grupo Quiero",
    stack: "Photoshop, Illustrator, InDesign",
  },
  {
    type: "graphic",
    title: "Art Design",
    description: "Digital art and textures",
    imgUrl: artNorush,
    info: "I previously co-founded a brand called NoRush alongside my partner, where my primary role encompassed conceptualizing and developing design ideas. The following examples showcase the designs we employed for wallets, a snapshot of our creative journey.",
    repo: "",
    video: "",
    test: "",
    pictures: ["art-1.png", "art-2.png", "art-3.png"],
    client: "",
    company: "NoRush",
    stack: "Photoshop, Illustrator, Corel Draw",
  },
  {
    type: "graphic",
    title: "Logos",
    description: "Branding, restyling, new brands",
    imgUrl: logotype,
    info: "As a designer, I've crafted a myriad of logos, predominantly catering to small and emerging companies. This focus arises from the fact that established larger corporations already possess well-established and recognizable brand identities.",
    repo: "",
    video: "",
    test: "",
    pictures: ["logos-1.png", "logos-2.png", "logos-3.png", "logos-4.png"],
    client: "",
    company: "Pubext, Imaginall, freelancer",
    stack: "Photoshop, Illustrator, Corel Draw",
  },
  {
    type: "graphic",
    title: "UX/UI Design",
    description: "User experience and interface designs",
    imgUrl: uxui,
    info: "To enrich my hybrid skill set, I pursued and successfully obtained a professional UX/UI Design certificate from Google. This achievement has enabled me to bridge the gap between my roles, allowing me to converse fluently with UX designers. While I haven't formally ventured into this field professionally, I consistently integrate my expertise while crafting the front-end of applications.",
    repo: "",
    video: "",
    test: "",
    pictures: ["digiart-ux.png", "uxui-1.webp", "uxui-2.png"],
    client: "",
    company: "Freelancer",
    stack: "Photoshop, Illustrator, Figma, AdobeXD",
  },
];

export default graphicDesigns;
