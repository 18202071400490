import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import { Zoom } from "react-awesome-reveal";
import { Fade } from "react-awesome-reveal";

//Components
import mySelf from "../../assets/ship/yo.svg";
import arrowDown from "../../assets/icons/arrow_down.svg";

//Styles
import "./header.scss";

export const MySelf = () => {
  return (
    <header className="banner" id="aboutme">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={12} lg={7}>
            <TrackVisibility once>
              <Fade>
                <div>
                  <span className="tagline">Hello everyone! I'm</span>
                  <h1>Franco Gabriel Amoroso</h1>
                  <p>
                    My journey with technology began in my childhood and has
                    remained my passion ever since. This connection sprouted
                    from my father's ownership of a computer store in the 90s,
                    enveloping me in an environment filled with vintage
                    computers. Those days held a unique charm, especially with
                    the emergence of the internet.
                    <br />
                    The realm of software and hardware has consistently felt
                    like a second nature to me, becoming not only my favorite
                    hobby but also my most cherished tool.
                  </p>
                </div>
              </Fade>
            </TrackVisibility>
          </Col>

          <Col xs={12} sm={12} lg={5} className="ship-container">
            <TrackVisibility once>
              <Zoom>
                <div>
                  <img className="ship" src={mySelf} alt="Header img" />
                </div>
              </Zoom>
            </TrackVisibility>
          </Col>

          <Col xs={12} sm={12} className="arrow-down">
            <a href="#faq">
              <img src={arrowDown} alt="Arrow pointing down" />
            </a>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
