import bug from "../../../assets/projects/bug.webp";
import quiero from "../../../assets/projects/quiero.webp";
import micropack from "../../../assets/projects/micropack.webp";
import norush from "../../../assets/projects/norush.webp";
import cad from "../../../assets/projects/cad.webp";
import random from "../../../assets/projects/random.webp";

const industrialDesigns = [
  {
    type: "industrial",
    title: "NoRush",
    description: "Strong and light Tyvek® wallets",
    imgUrl: norush,
    info: "NoRush is a collaborative venture between my partner and myself. Our mission was to conceive a wallet that not only exudes strength and style but also champions recyclability. I took charge of crafting the entire product, while together, we innovated a home-based production process that ensures the creation of these exceptional wallets.",
    repo: "",
    video: "",
    test: "",
    pictures: [
      "norush-1.png",
      "norush-2.png",
      "norush-3.png",
      "norush-4.png",
      "norush-5.png",
    ],
    client: "",
    company: "NoRush",
    stack: "Photoshop, Illustrator, Corel Draw",
  },
  {
    type: "industrial",
    title: "Grupo Quiero",
    description: "Retail Design, Branding, Look & Feel",
    imgUrl: quiero,
    info: "My tenure at Grupo Quiero stands as the pinnacle of my journey as an Industrial Designer. It was an unparalleled experience that provided me with the privilege to collaborate with global industry giants, spearheading projects that spanned across the entire LATAM region. My years of involvement with them present a remarkable array of endeavors, making it challenging to select just a few examples.",
    repo: "",
    video: "",
    test: "",
    pictures: [
      "quiero-1.png",
      "quiero-2.png",
      "quiero-3.png",
      "quiero-4.png",
      "quiero-5.png",
    ],
    client: "L'Oréal Paris, Movistar, Ford, and many other brands.",
    company: "Grupo Quiero",
    stack: "Photoshop, Illustrator, Rhinoceros 3D, 3DsMax, Vray",
  },
  {
    type: "industrial",
    title: "Micropack",
    description: "Retail Design using micro-corrugated cardboard",
    imgUrl: micropack,
    info: "During my time at Micropack, which was relatively short, my heart became entwined with the company as it marked the commencement of my professional journey. The designs showcased here weren't solely my creations; rather, they were collaborative efforts with my teammates. Throughout my tenure, I played a pivotal role in crafting prototypes and meticulously conducting comprehensive testing procedures.",
    repo: "",
    video: "",
    test: "",
    pictures: ["micro-1.png", "micro-2.png", "micro-3.png"],
    client: "Unilever, Coca-Cola, Ferrero and many other brands.",
    company: "Micropack",
    stack: "Photoshop, Illustrator, Corel Draw",
  },
  {
    type: "industrial",
    title: "Engineering",
    description: "CAD systems and technical draws",
    imgUrl: cad,
    info: "In several countries, Industrial Designers are often classified as engineers due to our adeptness in producing technical drawings, performing physics calculations, and possessing comprehensive insight into industrial processes and technologies. Our involvement in projects spans from initial sketches to prototype testing and culminates in overseeing large-scale production processes.",
    repo: "",
    video: "",
    test: "",
    pictures: ["cad-1.png", "cad-2.png", "cad-3.png", "cad-4.png", "cad-5.png"],
    client: "Rimmel, New Balance, Pepsico and many other brands.",
    company: "Grupo Quiero, Ikea, freelancer",
    stack: "Autocad, Illustrator, Corel Draw, Solidworks, Rhinoceros 3D",
  },
  {
    type: "industrial",
    title: "BUG",
    description: "A mechanical leaf sweeper",
    imgUrl: bug,
    info: "Initiated as my final project at the university, Project BUG has since transformed into a personal venture. While I'm no longer actively dedicated to its development, its inherent value and potential remain intact.",
    repo: "",
    video:
      "https://www.youtube.com/watch?v=gl_RSSi1YCo&ab_channel=FrancoAmoroso",
    test: "",
    pictures: ["bug-1.png", "bug-2.png", "bug-3.png", "bug-4.png"],
    client: "",
    company: "Freelancer",
    stack: "Rhinoceros 3D, Solidworks, Keyshot, Photoshop, Corel Draw",
  },
  {
    type: "industrial",
    title: "Product design",
    description: "A bit more about my projects",
    imgUrl: random,
    info: "This is a compilation of various other projects that have played a significant role in shaping my journey. While many of these are conceptual in nature, their impact on my career has been invaluable.",
    repo: "",
    video: "",
    test: "",
    pictures: [
      "random-1.webp",
      "random-2.png",
      "random-3.png",
      "random-4.png",
      "random-5a.png",
      "random-5b.png",
    ],
    client: "EstudioFIX, Pinkmask and more",
    company: "",
    stack: "Rhinoceros 3D, 3D-Max, Vray, Photoshop, Corel Draw",
  },
];

export default industrialDesigns;
